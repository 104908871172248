import React, { useState,useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {FaArrowCircleUp} from "react-icons/fa"
import { Link } from 'react-router-dom';

const MainPage = () => {
    const [fields, setFields] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
    };
  
    const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        if (scrollTop > 2) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
    };
  
    function handleFieldChange(event) {
      const { value } = event.target;
      setFields(value);
    }
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      const urlList = fields.split("\n").filter((str) => str.trim() !== "");

      console.log(urlList);
  
      if (urlList.length === 0) {
        toast.warning('Please enter URL names to check', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
  
      setLoading(true);
  
      try {
        const response = await fetch(`https://mozchecker.props.id/processUrl`, {
          method: "POST",
          body: JSON.stringify({"urlList":urlList}),
          headers:{
              'Content-Type': 'application/json',
          }
        })
    
        if (!response.ok) {
          throw new Error(`Failed to fetch data, status code: ${response.status}`);
        }
    
        const tmp = await response.json();
        if (Array.isArray(tmp)) {
          for (let row of tmp) {
            row.blocked = "Not Blocked";
            if (row.data.spam_score > 49) {
              row.blocked = "Blocked (reason: high spam score)";
            } else if (row.data.spam_score == -1) {
              row.blocked = "Please check the website is new";
            } else {
              if (row.data.pages_crawled_from_root_domain < 1000) {
                if (row.data.domain_authority < 8) {
                  row.blocked = "Blocked (reason: low DA with below 1.000 crawled page";
                }
              }else if (row.data.pages_crawled_from_root_domain < 100000) {
                if (row.data.domain_authority < 10) {
                  row.blocked = "Blocked (reason: low DA with below 100.000 crawled page";
                }
              }else if (row.data.pages_crawled_from_root_domain < 10000000) {
                if (row.data.domain_authority < 12) {
                  row.blocked = "Blocked (reason: low DA with below 10.000.000 crawled page";
                }
              } else {
                if (row.data.domain_authority < 14) {
                  row.blocked = "Blocked (reason: low DA with bigger than 10.000.000 crawled page";
                }
              }
            }
          }
        }
        setData(tmp);
        setFields('');
      } catch (error) {
        console.error(error);
        toast.error('Failed to fetch data',{
          position:toast.POSITION.TOP_RIGHT
        })
      } finally {
        setLoading(false);
      }
    };

    return (
        <div>
        <div>
            <form onSubmit={handleSubmit}>
                <h1 className="mb-2">Spam Check</h1>
                <hr />
                <p style={{ marginBottom: '4px' }}>Url Names</p>
                <textarea
                    value={fields}
                    onChange={handleFieldChange}
                    rows={5}
                    placeholder="Enter Url Names"
                />
                <div style={{ marginTop: '15px' }}>
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? 'Loading...' : 'Submit'}
                    </button>
                </div>
                <ToastContainer />
            </form>
        </div>
        {data.length !== 0 ?
        <div className='result'>
          <h1>Result</h1>
          {data && (
            <div className='container mt-5'>
              <div className='table-responsive'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>URL Name</th>
                      <th>Blocked</th>
                      <th>Page Authority</th>
                      <th>Domain Authority</th>
                      <th>Spam Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((field, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <a href={`https://${field.url}`} style={{textDecoration:"none"}} target="_blank" rel="noopener noreferrer">{field.url}</a>
                        </td>
                        <td>{field.blocked}</td>
                        <td>{field.data.page_authority}</td>
                        <td>{field.data.domain_authority}</td>
                        <td>{field.data.spam_score}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>      
        : null}
        <Link
            className="scroll-to-top-button"
            style={{ display: showButton ? 'block' : 'none' }}
            onClick={scrollToTop}
        >
            <FaArrowCircleUp size="30px" />
        </Link>
    </div>
    );
};

export default MainPage;